import { Playlists } from "@/features/playlist/domain/models/playlist";
import { HttpGateway } from "@/features/playlist/infrastructure/services/http";

export type GetPlaylistsResponse = Playlists;

export const playlistDataSourceImplementation = (gateway: HttpGateway) => ({
  getPlaylists: async <T>(): Promise<T> => {
    try {
      return await gateway.get("/creators/playlists");
    } catch (e) {
      throw Error("Error while getting playlists", {
        cause: e
      });
    }
  }
});
