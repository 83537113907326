import { PROFILE } from "@/constants/routes";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Logo, Title } from "@leeloo/core";

import { ButtonBack } from "../button-back/ButtonBack";
import { Icon } from "../icon/Icon";
import * as styles from "./header.css";

interface HeaderProps {
  className?: string;
  title?: string;
  withButtonBack?: boolean;
  withMobileButtonBack?: boolean;
  withMobileCloseIcon?: boolean;
  rightItem?: React.ReactNode;
  withLogo?: boolean;
  onClick?: () => void;
}

export function Header({
  className,
  title,
  withButtonBack,
  withMobileButtonBack,
  withMobileCloseIcon,
  rightItem,
  withLogo,
  ...rest
}: HeaderProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={clsx(styles.header, className)}
      data-testid="header"
      {...rest}
    >
      {withMobileCloseIcon && (
        <Icon
          className={styles.closeIcon({
            withMobileCloseIcon: withMobileCloseIcon
          })}
          name="close"
          data-testid="header-mobile-close-icon"
          onClick={() => navigate(PROFILE)}
          size="large"
        />
      )}
      {rightItem && <div className={styles.rightAction}>{rightItem}</div>}
      {withButtonBack && (
        <ButtonBack
          className={styles.buttonBack({
            withButtonBack: withButtonBack
          })}
          label={t("common_back_button_label")}
          data-testid="header-button-back"
        />
      )}
      {withMobileButtonBack && !withMobileCloseIcon && (
        <ButtonBack
          className={styles.mobileButtonBack({
            withMobileButtonBack: withMobileButtonBack
          })}
          label={t("common_back_button_label")}
          data-testid="button-back"
        />
      )}
      {withLogo && <Logo className={styles.logo} />}
      <Title as="h3" data-testid="header-title" className={styles.title}>
        {title}
      </Title>
    </div>
  );
}
