export interface StoreMedia {
  file?: File;
  properties: {
    url: string;
    type: string;
    id?: string;
    size?: number;
    extension?: string;
    preview?: string;
  };
}

export enum PostCreationStatus {
  SETUP = "setup",
  SCHEDULE = "schedule",
  CONFIRMATION = "confirmation",
  VISIBILITY = "visibility"
}

export interface PostState {
  media: StoreMedia | null;
  caption: string;
  isPostConfirmed: boolean;
  isPrivate: boolean;
  isScheduled: boolean;
  status: PostCreationStatus;
  publicationDate: Date | null;
  playlists: Array<string>;
}

export interface PostAction {
  addMedia: (file: StoreMedia) => void;
  deleteMedia: () => void;
  resetStore: () => void;
  updateCaption: (caption: string) => void;
  updateIsPostConfirmed: (isPostConfirmed: boolean) => void;
  updateIsPrivate: (isPrivate: boolean) => void;
  updateIsScheduled: (isScheduled: boolean) => void;
  updatePublicationDate: (publicationDate: Date) => void;
  updateStatus: (status: PostCreationStatus) => void;
  updatePlaylists: (playlists: Array<string>) => void;
}

export interface PostSlice extends PostState, PostAction {}

export interface PersistedPostSlice {
  isSetupCartridgeDisplayed: boolean;
  isVisibilityCartridgeDisplayed: boolean;
  setIsSetupCartridgeDisplayed: (value: boolean) => void;
  setIsVisibilityCartridgeDisplayed: (value: boolean) => void;
}

export const store = (store: PostSlice) => ({
  media: store.media,
  caption: store.caption,
  isPostConfirmed: store.isPostConfirmed,
  isPrivate: store.isPrivate,
  isScheduled: store.isScheduled,
  publicationDate: store.publicationDate,
  status: store.status,
  playlists: store.playlists,
  addMedia: store.addMedia,
  deleteMedia: store.deleteMedia,
  resetStore: store.resetStore,
  updateCaption: store.updateCaption,
  updateIsPostConfirmed: store.updateIsPostConfirmed,
  updateIsPrivate: store.updateIsPrivate,
  updateIsScheduled: store.updateIsScheduled,
  updatePublicationDate: store.updatePublicationDate,
  updateStatus: store.updateStatus,
  updatePlaylists: store.updatePlaylists
});

export const persistedStore = (store: PersistedPostSlice) => ({
  isSetupCartridgeDisplayed: store.isSetupCartridgeDisplayed,
  isVisibilityCartridgeDisplayed: store.isVisibilityCartridgeDisplayed,
  setIsSetupCartridgeDisplayed: store.setIsSetupCartridgeDisplayed,
  setIsVisibilityCartridgeDisplayed: store.setIsVisibilityCartridgeDisplayed
});
