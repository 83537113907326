import { HttpGateway } from "@/features/post/infrastructure/services/http";

export type CreatePostParams = {
  medias: string[];
  private: boolean;
  scheduledAt?: string;
  caption?: string;
  playlists?: string[];
};

export interface GetCalendarRestrictionsResponse {
  dates: Array<string>;
}

export interface GetPostCalendarDatesResponse {
  dates: Array<string>;
}

export const postDataSourceImplementation = (gateway: HttpGateway) => ({
  createPost: async <T>(params: CreatePostParams): Promise<T> => {
    try {
      return await gateway.post("/posts", params);
    } catch (e) {
      throw Error("Error while creating post", {
        cause: e
      });
    }
  },
  getCalendarRestrictions: async <T>(): Promise<T> => {
    try {
      return await gateway.get("/posts/calendar/restrictions");
    } catch (e) {
      throw Error("Error while getting calendar restrictions", {
        cause: e
      });
    }
  },
  getPostCalendarDates: async <T>(): Promise<T> => {
    try {
      return await gateway.get("/posts/calendar");
    } catch (e) {
      throw Error("Error while getting post calendar dates", {
        cause: e
      });
    }
  }
});
