import { useState } from "react";

import { CREATOR_PROFILE, POST_CONFIGURATION } from "@/constants/routes";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { Button, Loader, Progression } from "@leeloo/core";

import { Content } from "@/components/content/Content";
import EmptyState from "@/components/empty-state";
import { Error } from "@/components/error";
import { Header } from "@/components/header/Header";
import { Icon } from "@/components/icon/Icon";
import InfiniteScroll from "@/components/infinite-scroll";
import MediaGridSelection from "@/components/media-grid-selection";
import { Sidebar } from "@/components/sidebar/Sidebar";

import { Media } from "@/features/post/domain/models/media";
import { PostCreationStatus } from "@/features/post/infrastructure/datastores/store/store";
import MymCloudActionBar from "@/features/post/presentation/containers/mym-cloud-action-bar/MymCloudActionBar";
import {
  useCollectionByIdInfiniteQuery,
  useStore
} from "@/features/post/presentation/pages/collection/viewModel";

import * as styles from "./collection.css";

const Collection = () => {
  const { t } = useTranslation();
  const [selectedMedias, setSelectedMedias] = useState<Media[]>([]);
  const navigate = useNavigate();
  const { status, addMedia } = useStore();
  const {
    data: collection,
    isLoading,
    isError,
    refetch
  } = useCollectionByIdInfiniteQuery();

  const selectItem = (media?: Media) => {
    if (media) {
      setSelectedMedias((prevSelectedMedias) => {
        const isMediaSelected = prevSelectedMedias.some(
          (selectedMedia) => selectedMedia.id === media.id
        );

        if (isMediaSelected) {
          return prevSelectedMedias.filter(
            (selectedMedia) => selectedMedia.id !== media.id
          );
        } else {
          return [media];
        }
      });
    }
  };

  return (
    <Content
      sidebar={
        <Sidebar
          progression={
            <Progression
              header={{
                icon: "push",
                title: t("new_post_desktop_title")
              }}
              steps={[
                {
                  label: t("new_post_desktop_label_one"),
                  status:
                    status == PostCreationStatus.SETUP
                      ? "in_progress"
                      : "success",
                  link: POST_CONFIGURATION,
                  icon: <Icon name="chevron-right" />
                }
              ]}
              withRouter={Link}
            />
          }
        />
      }
      header={<Header title={collection?.name} withButtonBack />}
    >
      {isLoading ? (
        <Loader
          spin={true}
          data-testid="collection-loader"
          className={styles.loader}
        />
      ) : isError ? (
        <Error onClick={() => refetch()} data-testid="collection-error" />
      ) : !collection || !collection.medias?.length ? (
        <EmptyState
          translations={{
            title: t("mymcloud_recent_empty_state__title"),
            description: t("mymcloud_recent_empty_state__description")
          }}
        />
      ) : (
        <div className={styles.container} data-testid="collection">
          <InfiniteScroll
            hasMore={false}
            loadMore={() => ({})}
            isLoading={false}
          >
            <MediaGridSelection
              medias={collection?.medias || []}
              onSelectItem={selectItem}
              selectedMedias={selectedMedias}
            />
          </InfiniteScroll>
          <MymCloudActionBar
            data-testid="collection-action-bar"
            selectedMedias={selectedMedias}
            button={
              <Button
                data-testid="collection-add-media-button"
                onPress={() => {
                  addMedia({
                    properties: {
                      id: selectedMedias[0].id,
                      preview: selectedMedias[0].preview,
                      url: selectedMedias[0].url,
                      type: selectedMedias[0].type
                    }
                  });
                  navigate(POST_CONFIGURATION);
                }}
                disabled={selectedMedias.length === 0}
              >
                {t("mymcloud_recent_empty_state_bottombar_button")}
              </Button>
            }
          />
        </div>
      )}
    </Content>
  );
};

export default Collection;
