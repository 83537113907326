import React from "react";

import {
  POST_CONFIGURATION,
  POST_CONFIRMATION,
  POST_MYM_CLOUD,
  POST_MYM_CLOUD_COLLECTION,
  POST_MYM_CLOUD_COLLECTIONS
} from "@/constants/routes";
import { RouteConfig } from "@/routes/routes";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { PostCreationStatus } from "@/features/post/infrastructure/datastores/store/store";
import { useStore } from "@/features/post/presentation/pages/setup/viewModel";

import Collection from "../pages/collection/Collection";

const Setup = React.lazy(
  () => import("@/features/post/presentation/pages/setup")
);

const MymCloud = React.lazy(
  () => import("@/features/post/presentation/pages/mym-cloud")
);

const Confirmation = React.lazy(
  () => import("@/features/post/presentation/pages/confirmation")
);

const ConfirmationElement = () => {
  const { status } = useStore();
  return status == PostCreationStatus.CONFIRMATION ? (
    <Confirmation />
  ) : (
    <Navigate to={`../${POST_CONFIGURATION}`} replace />
  );
};

const routesConfig: RouteConfig[] = [
  {
    path: POST_CONFIGURATION,
    element: <Setup />,
    isPrivate: true
  },
  {
    path: POST_MYM_CLOUD,
    element: <MymCloud />,
    isPrivate: true
  },
  {
    path: POST_MYM_CLOUD_COLLECTION,
    element: <Collection />,
    isPrivate: true
  },
  {
    path: POST_CONFIRMATION,
    element: <ConfirmationElement />,
    isPrivate: true
  }
];

export const PostRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        {routesConfig.map((route, index) => {
          return (
            <Route key={index} path={route.path} element={route.element} />
          );
        })}
      </Route>
    </Routes>
  );
};

export default PostRoutes;
