import {
  blockedPhrasesKey,
  playlistsKey,
  postCalendarDatesKey,
  postCalendarRestrictionsKey
} from "@/constants/queryKeys";
import { POST_CONFIRMATION } from "@/constants/routes";
import { getMediaResolution } from "@/utils/mediaResolution";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { startOfDay } from "date-fns";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { showToast } from "@leeloo/core";

import { MIN_IMAGE_DIMENSION } from "@/features/post/domain/constants";
import {
  CreatePresignedUrlParams,
  UploadMediaParams
} from "@/features/post/domain/repositories/media";
import blockedPhrasesUseCases from "@/features/post/domain/use-cases/blocked-phrases";
import mediaUseCases from "@/features/post/domain/use-cases/media";
import playlistUseCases from "@/features/post/domain/use-cases/playlist";
import postUseCases from "@/features/post/domain/use-cases/post";
import {
  GetBlockedPhrasesResponse,
  blockedPhrasesDataSourceImplementation
} from "@/features/post/infrastructure/datasources/blocked-phrases/blockedPhrasesDataSourceImplementation";
import {
  CreatePresignedUrlResponse,
  mediaDataSourceImplementation
} from "@/features/post/infrastructure/datasources/media/mediaDataSourceImplementation";
import {
  GetPlaylistsResponse,
  playlistDataSourceImplementation
} from "@/features/post/infrastructure/datasources/playlist/playlistDataSourceImplementation";
import {
  CreatePostParams,
  GetCalendarRestrictionsResponse,
  GetPostCalendarDatesResponse,
  postDataSourceImplementation
} from "@/features/post/infrastructure/datasources/post/postDataSourceImplementation";
import {
  PostCreationStatus,
  StoreMedia,
  persistedStore,
  store
} from "@/features/post/infrastructure/datastores/store/store";
import {
  persistedStoreImplementation,
  storeImplementation
} from "@/features/post/infrastructure/datastores/store/storeImplementation";
import httpImplementation from "@/features/post/infrastructure/services/httpImplementation";

import { useProcessImage } from "@/hooks/useProcessImage";

export const useStore = () => {
  const storeImpl = store(storeImplementation());

  return {
    media: storeImpl.media,
    caption: storeImpl.caption,
    isPrivate: storeImpl.isPrivate,
    isScheduled: storeImpl.isScheduled,
    publicationDate: storeImpl.publicationDate,
    status: storeImpl.status,
    playlists: storeImpl.playlists,
    addMedia: (file: StoreMedia) => storeImpl.addMedia(file),
    deleteMedia: () => storeImpl.deleteMedia(),
    updateCaption: (caption: string) => storeImpl.updateCaption(caption),
    updatePublicationDate: (date: Date) =>
      storeImpl.updatePublicationDate(date),
    updateStatus: (status: PostCreationStatus) =>
      storeImpl.updateStatus(status),
    updateIsPrivate: (isPrivate: boolean) =>
      storeImpl.updateIsPrivate(isPrivate),
    updateIsScheduled: (isScheduled: boolean) =>
      storeImpl.updateIsScheduled(isScheduled),
    resetStore: () => storeImpl.resetStore(),
    updatePlaylists: (playlists: Array<string>) =>
      storeImpl.updatePlaylists(playlists)
  };
};

export const useCheckTextComplianceMutation = () => {
  const blockedPhrasesDataSourceImpl = blockedPhrasesDataSourceImplementation(
    httpImplementation()
  );
  const useCase = blockedPhrasesUseCases(blockedPhrasesDataSourceImpl);

  return useMutation({
    mutationKey: [blockedPhrasesKey],
    mutationFn: (caption: string) =>
      useCase.checkTextCompliance<AxiosResponse<GetBlockedPhrasesResponse>>(
        caption
      )
  });
};

export const usePersistedStore = () => {
  const persistedStoreImpl = persistedStore(persistedStoreImplementation());

  return {
    isSetupCartridgeDisplayed: persistedStoreImpl.isSetupCartridgeDisplayed,
    setIsSetupCartridgeDisplayed:
      persistedStoreImpl.setIsSetupCartridgeDisplayed,
    isVisibilityCartridgeDisplayed:
      persistedStoreImpl.isVisibilityCartridgeDisplayed,
    setIsVisibilityCartridgeDisplayed:
      persistedStoreImpl.setIsVisibilityCartridgeDisplayed
  };
};

export const useCalendarRestrictionsQuery = () => {
  const httpClient = httpImplementation();
  const postDataSourceImpl = postDataSourceImplementation(httpClient);
  const useCasePost = postUseCases(postDataSourceImpl);

  return useQuery({
    queryKey: [postCalendarRestrictionsKey],
    queryFn: () =>
      useCasePost.getCalendarRestrictions<
        AxiosResponse<GetCalendarRestrictionsResponse>
      >(),
    select: (response) => {
      return response.data.dates.map((date) => startOfDay(new Date(date)));
    }
  });
};

export const useCalendarPostDatesQuery = () => {
  const httpClient = httpImplementation();
  const postDataSourceImpl = postDataSourceImplementation(httpClient);
  const useCasePost = postUseCases(postDataSourceImpl);

  return useQuery({
    queryKey: [postCalendarDatesKey],
    queryFn: () =>
      useCasePost.getPostCalendarDates<
        AxiosResponse<GetPostCalendarDatesResponse>
      >(),
    select: (response) => {
      return response.data.dates.map((date) => startOfDay(new Date(date)));
    }
  });
};

export const useCreatePostMutation = () => {
  const httpClient = httpImplementation();
  const postDataSourceImpl = postDataSourceImplementation(httpClient);
  const useCasePost = postUseCases(postDataSourceImpl);

  return useMutation({
    mutationKey: ["create-post"],
    mutationFn: (payload: CreatePostParams) =>
      useCasePost.createPost<AxiosResponse>(payload),
    onSuccess: async (response) => {
      return response.data;
    }
  });
};

export const useCreatePresignedUrlMutation = () => {
  const httpClient = httpImplementation();
  const mediaDataSourceImpl = mediaDataSourceImplementation(httpClient);
  const useCaseMedia = mediaUseCases(mediaDataSourceImpl);

  return useMutation({
    mutationKey: ["post-create-presigned-url"],
    mutationFn: (payload: CreatePresignedUrlParams) =>
      useCaseMedia.createPresignedUrl<
        AxiosResponse<CreatePresignedUrlResponse>
      >(payload),
    onSuccess: (response) => response.data
  });
};

export const useUploadMutation = () => {
  const httpClient = httpImplementation({
    baseURL: "",
    withInterceptor: false
  });
  const mediaDataSourceImpl = mediaDataSourceImplementation(httpClient);
  const useCaseMedia = mediaUseCases(mediaDataSourceImpl);

  const { execute } = useProcessImage();

  return useMutation({
    mutationKey: ["post-upload-media"],
    mutationFn: async (payload: UploadMediaParams) => {
      const processedFile = await execute(payload.file);
      const processedPayload = {
        url: payload.url,
        file: processedFile
      };

      return useCaseMedia.uploadMedia<AxiosResponse>(processedPayload);
    },

    onSuccess: (response) => response.data
  });
};

export const useSubmitPost = () => {
  const { t } = useTranslation();
  const {
    media,
    caption,
    isPrivate,
    publicationDate,
    updateStatus,
    playlists
  } = useStore();
  const { mutateAsync: onCreatePresignedUrl } = useCreatePresignedUrlMutation();
  const { mutateAsync: onUploadMedia } = useUploadMutation();
  const { mutateAsync: onCreatePost } = useCreatePostMutation();
  const navigate = useNavigate();

  return {
    onSubmitPost: async () => {
      try {
        if (media) {
          let mediaId = media?.properties?.id || null;
          if (!media?.properties.id && media.file) {
            const resolution = await getMediaResolution(
              media.file,
              MIN_IMAGE_DIMENSION,
              MIN_IMAGE_DIMENSION
            );

            const response = await onCreatePresignedUrl({
              media: {
                size: media.properties.size || 0,
                extension: media?.properties.extension || "",
                inCloud: true,
                resolution,
                type: media.properties.type
              }
            });

            if (response?.data?.url && media?.file) {
              await onUploadMedia({
                url: response.data.url,
                file: media.file
              });
              mediaId = media?.properties?.id || response?.data?.mediaId;
            }
          }

          if (mediaId && publicationDate) {
            await onCreatePost({
              medias: [mediaId],
              caption: caption,
              private: isPrivate,
              scheduledAt: publicationDate.toISOString(),
              playlists
            });

            updateStatus(PostCreationStatus.CONFIRMATION);
            navigate(`../${POST_CONFIRMATION}`);
          }
        }
      } catch (error) {
        showToast({
          description: t("technical_error_toast"),
          title: t("technical_error_toast_title"),
          variant: "error"
        });

        throw error;
      }
    }
  };
};

export const usePlaylistsQuery = () => {
  const httpClient = httpImplementation();
  const playlistDataSource = playlistDataSourceImplementation(httpClient);
  const playlistUC = playlistUseCases(playlistDataSource);

  return useQuery({
    queryKey: [playlistsKey],
    queryFn: () =>
      playlistUC.getPlaylists<AxiosResponse<GetPlaylistsResponse>>(),
    select: (response) => response.data
  });
};
