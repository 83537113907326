import { PlaylistRepository } from "@/features/post/domain/repositories/playlist";

export const playlistUseCases = (repository: PlaylistRepository) => ({
  getPlaylists: async <T>() => {
    try {
      return await repository.getPlaylists<T>();
    } catch (e: any) {
      throw Error(e, {
        cause: e?.cause
      });
    }
  }
});

export default playlistUseCases;
