import { useState } from "react";

import formatCurrency from "@/utils/convertPriceCurrency";
import { useTranslation } from "react-i18next";

import { Checkbox, Image, Text } from "@leeloo/core";

import { Icon } from "@/components/icon/Icon";

import * as styles from "./media-grid-selection-item.css";

type MediaGridSelectionItem = {
  id: string;
  preview: string;
  type: "video" | "image";
  duration?: number;
  private?: boolean;
  isScheduled?: boolean;
  price?: {
    value: number;
    currency: string;
  };
  imageCount?: number;
  videoCount?: number;
};

export interface MediaGridSelectionItemProps<T> {
  media: T;
  checked: boolean;
  disabled?: boolean;
  onSelectItem: (mediaId: string) => void;
}

const MediaGridSelectionItem = <T extends MediaGridSelectionItem>({
  media,
  checked,
  disabled,
  onSelectItem,
  ...props
}: MediaGridSelectionItemProps<T>) => {
  const { i18n } = useTranslation();
  const [imageError, setImageError] = useState<boolean>(false);

  const formatTime = (seconds: number) =>
    new Date(seconds * 1000).toISOString().substring(14, 19);

  return (
    <div
      className={styles.media({ disabled: disabled })}
      onClick={() => onSelectItem(media.id)}
      data-testid="media-grid-selection-item"
      {...props}
    >
      <span
        data-testid="mgsi-overlay"
        className={styles.overlay({ selected: checked })}
      />
      <Image
        src={media?.preview}
        data-testid="mgsi-image"
        className={styles.image}
        alt="Your media"
        loading="lazy"
        onError={() => setImageError(true)}
      />
      <Checkbox
        data-testid="mgsi-checkbox"
        className={styles.checkbox({ checked: checked })}
        value={media.id}
        key={media.id}
        aria-label="Selected media to create a post"
        onChange={() => onSelectItem(media.id)}
        checked={checked}
      />
      {!imageError ? (
        <>
          {media.isScheduled ? (
            <div
              className={styles.watermark}
              data-testid="mgsi-scheduled-watermark"
            >
              <Icon
                name="time"
                size="large"
                data-testid="mgsi-scheduled-watermark-icon"
              />
            </div>
          ) : media?.private ? (
            <div
              className={styles.watermark}
              data-testid="mgsi-private-watermark"
            >
              <Icon
                name="security"
                size="large"
                data-testid="mgsi-private-watermark-icon"
              />
            </div>
          ) : null}
          {media?.imageCount || media?.videoCount ? (
            <div className={styles.modInfo}>
              {media?.price ? (
                <Text
                  variant="aggressive"
                  className={styles.text}
                  data-testid="mgsi-price"
                >
                  {formatCurrency(
                    media?.price.value / 100,
                    media?.price.currency,
                    i18n.language
                  )}
                </Text>
              ) : null}
              {media.imageCount ? (
                <>
                  <Icon
                    name="picture"
                    aria-label="Video icon"
                    size="small"
                    data-testid="mgsi-picture-icon"
                  />
                  <Text className={styles.text} data-testid="mgsi-picture-text">
                    {media.imageCount}
                  </Text>
                </>
              ) : null}
              {media?.videoCount ? (
                <>
                  <Icon
                    name="camera"
                    aria-label="Video icon"
                    size="small"
                    data-testid="mgsi-video-icon"
                  />

                  <Text className={styles.text} data-testid="mgsi-video-text">
                    {media.videoCount}
                  </Text>
                </>
              ) : null}
            </div>
          ) : (
            media.type === "video" && (
              <div className={styles.duration} data-testid="mgsi-duration">
                <Icon
                  name="camera"
                  aria-label="Video icon"
                  size="small"
                  data-testid="mgsi-duration-icon"
                />
                <Text
                  className={styles.text}
                  variant="caption"
                  data-testid="mgsi-duration-text"
                >
                  {formatTime(media.duration || 0)}
                </Text>
              </div>
            )
          )}
        </>
      ) : null}
    </div>
  );
};

export default MediaGridSelectionItem;
